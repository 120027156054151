import React, {Fragment} from 'react';
import styled from 'styled-components'
import {RESPONSIVE_WIDTHS} from '../constants/constants';
import whiteRedBackgroundImage from '../assets/images/white-red.png';

export function HeroSection(props) {
  const image = props.image;
  const imageAlt = props.imageAlt;
  return (
      <Fragment>
        <div style={{paddingLeft: "0", paddingRight: "0"}}>
          <HeroWrapper>
            <HeroMediaWrapper>
              <HeroImg src={image} alt={imageAlt}/>
            </HeroMediaWrapper>
          </HeroWrapper>
          <HeroContent>
            <HeroTitle>{props.title}</HeroTitle>
            <HeroSubtitle>{props.subtitle}</HeroSubtitle>
          </HeroContent>
        </div>
      </Fragment>
  )
}

const HeroWrapper = styled.div`
  position: relative;
  @media (min-width: ${RESPONSIVE_WIDTHS.md}) {
  .hero {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: 720px;
  }
`;

const HeroMediaWrapper = styled.div`
  background-color: #000;
  overflow: hidden;
  height: 320px;
  @media (min-width: ${RESPONSIVE_WIDTHS.md}) {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
`;

const HeroImg = styled.img`
  background-color: #FFFFFF;
  display: block;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  @media (min-width: ${RESPONSIVE_WIDTHS.md}) {
    object-position: 1% bottom;
  }
`;

const HeroContent = styled.div`
  padding: 0;
  position: relative;
  width: 276px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${RESPONSIVE_WIDTHS.md}) {
    left: 40px;
    margin-left: 0;
    margin-right: 0;
    padding: 60px 80px;
    width: 650px !important;
    z-index: 9;
  }
  
  ::before, ::after {
    content: '';
    display: block;
    position: absolute;
    background-color: #fff;
    right: 0;
    top: -40px;
    width: 100vw;
    height: 40px;
    z-index: 11;
    @media (min-width: ${RESPONSIVE_WIDTHS.md}) {
      display: none;
    }
  }
`;


const HeroTitle = styled.div`
  font-size: 38px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 20px;
  @media (min-width: ${RESPONSIVE_WIDTHS.md}) {
    font-size: 68px;
    margin-bottom: 20px;
  }
`;


const HeroSubtitle = styled.div`
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
  @media (min-width: ${RESPONSIVE_WIDTHS.md}) {
    font-size: 34px;
    line-height: 1.2;
  }
`;

/*

.hero__content {
  padding: 0;
  position: relative;
  width: 276px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $min-width) {
    left: 40px;
    margin-left: 0;
    margin-right: 0;
    padding: 60px 80px;
    width: 650px !important;
    z-index: 9;
  }
}

.hero__title {
  font-size: 38px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 20px;
  @media (min-width: $min-width) {
    font-size: 68px;
    margin-bottom: 20px;
  }
}

.hero__subtitle {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
  @media (min-width: $min-width) {
    font-size: 34px;
    line-height: 1.2;
  }
}

.hero-details__content img {
  width: 100%;
  padding-bottom: 10px;
}

.content::before, .content::after {
  content: '';
  display: block;
  position: absolute;
  background-color: #fff;
  right: 0;
  top: -40px;
  width: 100vw;
  height: 40px;
  z-index: 11;
  @media (min-width: $min-width) {
    display: none;
  }
}
 */