import React, {Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';
import AboutView from './views/AboutView';
import NavbarHeader from './components/NavbarHeader';
import Footer from './components/Footer';
import PrivacyPolicyView from './views/PrivacyPolicyView';
import TermsView from './views/TermsView';
import FaqView from './views/FaqView';
import IdeasView from './views/IdeasView';
import ContactView from './views/ContactView';
import Error404View from './views/Error404View';

import './scss/halalmunch.scss'
import {NAVBAR_HEIGHT} from './constants/constants';

function App() {


  return (
      <Fragment>
        <NavbarHeader/>
        <div style={{marginTop: NAVBAR_HEIGHT, flex: 1}}>
          <Switch>
            <Route exact path={"/"}>
              <AboutView/>
            </Route>
            <Route exact path={"/privacy-policy"}>
              <PrivacyPolicyView/>
            </Route>
            <Route exact path={"/terms"}>
              <TermsView/>
            </Route>
            <Route exact path={"/faq"}>
              <FaqView/>
            </Route>
            <Route exact path={"/ideas"}>
              <IdeasView/>
            </Route>
            <Route exact path={"/contact"}>
              <ContactView/>
            </Route>
            {/*<Route exact path={"/add-restaurant"}>*/}
            {/*  <AddRestaurantView/>*/}
            {/*</Route>*/}
            {/*<Route exact path={"500"}>*/}
            {/*  <Error500View/>*/}
            {/*</Route>*/}
            <Route>
              <Error404View/>
            </Route>
            {/*<Redirect exact from="/" to={"/about"}/>*/}
          </Switch>
        </div>
        <Footer/>
      </Fragment>
  );
}

export default App;
