import React, {Fragment} from 'react';

import whiteRedBackgroundImage from '../assets/images/white-red.png';
import walkthroughImage from '../assets/videos/halalmunch_walkthrough.png';
import iphoneTemplate from '../assets/images/iphonex-template.png';
import {
  ShowcaseMedia,
  ShowcaseMediaImage,
  ShowcaseSection, ShowcaseTitle,
} from '../components/showcase/Showcase';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';

function AboutView() {
  return (
      <div style={{alignItems: 'center'}}>
        {/*<ShowcaseMediaImage src={whiteRedBackgroundImage} alt="white-red"/>*/}
        <ShowcaseSection
            style={{paddingTop: '70px'}}
            title={'find restaurants near you'}
            subtitle={'new restaurants, new experiences, all at your finger tips'}
            media={'/assets/videos/halalmunch_walkthrough.mp4'}
        >
          <Button className="btn btn-sm btn-primary text-right">
            <a className="text-white" href="https://halalmunch.com">Launch App</a>
          </Button>
        </ShowcaseSection>

        <ShowcaseSection
            title={'pick your location'}
            subtitle={'use your current location or enter an address and find hundreds of restaurants nearby'}
            media={'/assets/videos/halalmunch_address_location.mp4'}
            alternate
        />

        <ShowcaseSection
            title={'filter by categories'}
            subtitle={'select your favourite cuisines and "halalness" to find what you really like'}
            media={'/assets/videos/halalmunch_filter.mp4'}
        />

        <ShowcaseSection
            title={'go get your munch'}
            subtitle={'once you\'ve found a restaurant you can view their website, call them up, and get directions'}
            media={'/assets/videos/halalmunch_restaurant_details.mp4'}
            alternate

        />

      </div>
  );
}

export default AboutView;