import React, {Fragment} from 'react';

import whiteRedCounterImage from '../assets/images/white-red-counter.png'
import {HeroSection} from '../components/hero';
import cookiesImage from '../assets/images/cookies.png';
import styled from 'styled-components';
import addToHomeScreenButton from '../assets/images/add-to-home-screen/add-to-homescreen-button.jpg'
import addToHomeScreenPage from '../assets/images/add-to-home-screen/add-to-homescreen-page.jpg'
import addToHomeScreenResult from '../assets/images/add-to-home-screen/add-to-homescreen-result.jpg'
import addToHomeScreenPwa from '../assets/images/add-to-home-screen/add-to-homescreen-pwa.jpg'

function FaqView() {
  return (
      <Fragment>
        <div className="container">

          <HeroSection
              title={'Frequently Asked Questions'}
              subtitle={'A few common answers to a few common questions.'}
              image={whiteRedCounterImage}
              imageAlt={'white-red-counter'}
          />

          <section className="padded">
            <FaqBlock>
              <h4 className="question">Are all your restaurants halal?</h4>
              <p>
                We aim to cater to the everyone's palate, therefore we show
                halal, partially halal, and vegan options.
              </p>
            </FaqBlock>

            <FaqBlock>
              <h4 className="question">Do you have a mobile app?</h4>
              <p>
                We currently do not have a Halal Munch mobile app, but keep your
                eyes peeled.
              </p>
              <p>
                You can add our web app to your home screen, and it behaves just like an app!
              </p>

              <h5>On IOS:</h5>
              <ol>
                <li>Go to <a href={"https://halalmunch.com"}>halalmunch.com</a></li>
                <li>Click Share</li>
                <li>
                  Select "Add to Home Screen"
                  <br/>
                  <img src={addToHomeScreenButton} height={"500px"} alt={"Add to Home Screen Screenshot"}/>
                </li>
                <li>
                  Click Add
                  <br/>
                  <img src={addToHomeScreenPage} height={"500px"} alt={"Add to Home Screen Screenshot"}/>
                </li>
                <li>
                  The app icon should appear on you home screen
                  <br/>
                  <img src={addToHomeScreenResult} height={"500px"} alt={"Add to Home Screen Screenshot"}/>
                </li>
                <li>
                  And when you open it, you should see something like this. Just like an app from the App Store.
                  <br/>
                  <img src={addToHomeScreenPwa} height={"500px"} alt={"Add to Home Screen Screenshot"}/>
                </li>
              </ol>
            </FaqBlock>
          </section>

          <section className="padded">
            <p>
              For further enquiries please contact us <a href="/contact">get in
              touch</a>
            </p>
          </section>

          <br/>

        </div>

      </Fragment>
  )
}

const Answer = styled.p`
  //padding-bottom: 20px;
`;

const FaqBlock = styled.div`
  padding-bottom: 20px;
`;

export default FaqView;