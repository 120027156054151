import React, {Fragment} from 'react';
import '../scss/navbar.css'
import styled from 'styled-components';
import {useHistory} from 'react-router-dom'
import Logo from '../assets/logos/Halalmunch_logo_full.png'
import Navbar from 'react-bootstrap/Navbar';
import NavbarBrand from 'react-bootstrap/NavbarBrand';

function NavbarHeader() {

  const history = useHistory();

  function onNavbarClick() {
    history.push("/");
  }

  return (
      <Fragment>
        <Navbar expand={"md"} fixed={"top"} variant={"light"} >
          <NavMain >
            <NavbarBrand onClick={onNavbarClick}>
              <img
                  src={Logo}
                  height="25"
                  className="d-inline-block align-top" alt=""/>
            </NavbarBrand>
          </NavMain>
        </Navbar>
      </Fragment>
  )
}

const NavMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.7rem 2rem 0.5rem 2rem;
`;

export default NavbarHeader;