export const EMAIL = "hello@halalmunch.com";


export const SOCIAL_INSTAGRAM_LINK = "https://instagram.com/halalmunch";
export const SOCIAL_TWITTER_LINK = "https://twitter.com/halal_munch";
export const SOCIAL_FACEBOOK_LINK = "https://facebook.com/halalmunch";


// STYLES
export const RESPONSIVE_WIDTHS = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px"
};

// $theme-colors: (
//     "primary": #E74C3C,
//     "secondary": #F2DD72,
//     "success": #58D68D,
//     "info": #3498DB,
//     "warning": #F5B041,
//     "danger": #7B241C,
// );

export const BASE_COLOR = "#FFFFFF";
export const BASE_HOVER = "#f9f9f9";
export const BRAND_COLOR = "#E74C3C";
export const BRAND_80_COLOR = `rgba(${BRAND_COLOR}, 0.8)`;

export const LIGHT_GREY = "#757575";
export const BASE_DARK = "#262626";

export const BORDER_COLOR = "rgba(0, 0, 0, .08)";

export const COLOR_TEXT_CAPTION = "#242328";
export const COLOR_PRIMARY = "#E74C3C";
export const COLOR_PRIMARY_80 = `rgba(${COLOR_PRIMARY}, 0.8)`;

export const NAVBAR_HEIGHT = "60px";
export const FULL_SCREEN_HEIGHT = "100%";
export const FULL_APP_HEIGHT = FULL_SCREEN_HEIGHT - NAVBAR_HEIGHT;
export const MIN_WIDTH = "718px";
