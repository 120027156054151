import React, {Fragment, useState} from 'react';
import apiService from '../services/apiService';
import whiteRedCounterImage from '../assets/images/white-red-counter.png'
import {RECAPTCHA_SITE_KEY} from '../config/halalmunchAppConfig';
import ReCAPTCHA from 'react-google-recaptcha/lib/esm/recaptcha-wrapper';
import {HeroSection} from '../components/hero';

function ContactView() {

  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [recaptcha, setRecaptcha] = useState("");

  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const recaptchaRef = React.createRef();

  function submitForm() {
    setLoading(false);
    setMessage(null);
    setError(null);
    apiService.postEnquiry(email, subject, enquiry, recaptcha)
        .then((data) => setMessage(data.message))
        .catch((e) => setError("Oops.. it looks like we failed to submit your query, please try again"))
        .finally(() => setLoading(false));
  }

  function onRecaptchaComplete() {
    setRecaptcha(recaptchaRef.current.getValue());

  }

  function onEmailChange(event) {
    setEmail(event.target.value);
  }

  function onSubjectChange(event) {
    setSubject(event.target.value);
  }

  function onEnquiryChange(event) {
    setEnquiry(event.target.value);
  }


  return (
      <Fragment>
        <div className="container">
          <HeroSection
              title={"Contact Us"}
              subtitle={"Get answers to your queries."}
              image={whiteRedCounterImage}
              imageAlt={"white-red-counter"}
          />

          <section className="padded">
            <h4>Have you checked our FAQ?</h4>
            <p>
              You question may have already been answered on our <a
                href="/faq">FAQ</a>. Take a quick look to
              save yourself some time.
            </p>
            <p>
              If your question has not been answered, simple submit the form
              below and a representative will be in touch
              with you in due time. Please allow upto 3 weeks for a response.
            </p>
          </section>

          <section className="padded">
            <form>
              {message &&
              <div className="form-group">
                <div className="alert alert-success">
                {message}
                </div>
              </div>
              }
              {error &&
              <div className="form-group">
                <div className="alert alert-danger">
                {error}
                </div>
              </div>
              }

              <div className="form-group">
                <label htmlFor="email">You email</label>
                <input type="email" name="email"
                       placeholder="For us to get back to you"
                       className="form-control"
                       onChange={onEmailChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="subject">Subject</label>
                <input type="text" name="subject"
                       placeholder="Summarise your enquiry"
                       className="form-control"
                       onChange={onSubjectChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="enquiry">Enquiry</label>
                <textarea name="enquiry" className="form-control"
                          rows="5"
                          onChange={onEnquiryChange}
                />
              </div>

              <div className="form-group">
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={onRecaptchaComplete}
                />
              </div>

              <div className="form-group">
                <button id="submit-button" type="button"
                        className="btn btn-lg btn-block btn-primary"
                        onClick={submitForm}
                >
                  Submit Enquiry
                </button>
              </div>

              <br/>
            </form>
          </section>

          <br/>
        </div>


      </Fragment>
  )
}

export default ContactView;