import styled from 'styled-components';
import whiteRedBackgroundImage from '../../assets/images/white-red.png';
import React, {Fragment} from 'react';
import {COLOR_PRIMARY, RESPONSIVE_WIDTHS} from '../../constants/constants';
import iphoneTemplate from '../../assets/images/iphonex-template.png'
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import walkthroughImage from '../../assets/videos/halalmunch_walkthrough.png';

export function ShowcaseSection(props: {}) {
  const title = props.title;
  const subtitle = props.subtitle;
  const media = props.media;
  const poster = props.poster | walkthroughImage;
  const alternate = props.alternate | false;

  const flexWrap = alternate ? "wrap-reverse" : "wrap";

  function renderTitle() {
    return (
        <Col md={6} style={{marginBottom: "20px"}}>
          <ShowcaseTitle>{title}</ShowcaseTitle>
          {subtitle &&
          <ShowcaseSubtitle >{subtitle}</ShowcaseSubtitle>
          }
          {props.children}
        </Col>
    )
  }

  function renderMedia() {
    return (
        <Col md={6}>
          <ShowcaseMedia src={media} poster={walkthroughImage}/>
        </Col>
    )
  }

  return (
      <ShowcaseSectionWrapper style={{...props.style}}>
        <div style={{width: '100%', display: "flex", flexWrap: flexWrap, alignItems: 'center'}}>
          {alternate ?
              <Fragment>
                {renderMedia()}
                {renderTitle()}
              </Fragment>
              :
              <Fragment>
                {renderTitle()}
                {renderMedia()}
              </Fragment>
          }
        </div>
      </ShowcaseSectionWrapper>
  )
}


function ShowcaseMedia(props : {src: string, poster: any}) {
  return (
      <ShowcaseImageWrapper style={{zIndex: 10}}>
        <img src={iphoneTemplate}
             alt="iphonex-template"
             height={"500px"}
             style={{position: "absolute"}}
        />
        <video
            height={"475.877227912014518px"}
            poster={props.poster}
            autoPlay playsInline loop muted
        >
          <source
              src={process.env.PUBLIC_URL + props.src}
              type="video/mp4"
          />
        </video>
      </ShowcaseImageWrapper>
  )
}

const ShowcaseMediaWrapper = styled.div`
  position: absolute;
  height: 100%;
  left: 40%;
  top: 0;
  width: 100%;
  opacity: 0.6;
`;

export function ShowcaseMediaImage(props: {src: string, alt: string}) {
  return (
      <ShowcaseMediaWrapper>
        <img src={props.src} alt={props.alt | ""}/>
      </ShowcaseMediaWrapper>
  )
}

const ShowcaseSectionWrapper = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  //overflow-x: hidden;
  padding: 40px 0;
  
  @media screen and (min-width: ${RESPONSIVE_WIDTHS.md}) {
    min-width: 70%;
    max-width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: auto;
  }
`;

const ShowcaseTitle = styled.div`
  @media screen and (min-width: ${RESPONSIVE_WIDTHS.md}) {
    font-size: 40px;
  }
  font-size: 30px;
  color: ${COLOR_PRIMARY};
`;

const ShowcaseSubtitle = styled.div`
font-size: 20px;
  font-weight: lighter;
  color: #242328;
  opacity: 0.7;
  padding: 0 2.5%;
  @media screen and (min-width: ${RESPONSIVE_WIDTHS.md}) {
    font-size: 30px;
  }
`;

const ShowcaseImageWrapper = styled.div`
text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;