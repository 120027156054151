import React from 'react';
import {
  SOCIAL_FACEBOOK_LINK,
  SOCIAL_INSTAGRAM_LINK,
  SOCIAL_TWITTER_LINK,
} from '../constants/constants';
import {Link} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

function Footer() {

  return (
      <FooterWrapper>
        <img
            src="/assets/logos/Halalmunch_logo_full.png"
            height="25"
            className="d-inline-block align-top" alt=""/>
        <hr/>
        <Row>
          <Col sm={4}>
            <FooterUl>
              <li><FooterStaticLink href={SOCIAL_FACEBOOK_LINK} target={"_blank"}>Facebook</FooterStaticLink>
              </li>
              <li><FooterStaticLink href={SOCIAL_TWITTER_LINK} target={"_blank"}>Twitter</FooterStaticLink></li>
                <li><FooterStaticLink href={SOCIAL_INSTAGRAM_LINK} target={"_blank"}>Instagram</FooterStaticLink>
              </li>
            </FooterUl>
          </Col>
          <Col sm={4}>
            <FooterUl>
              <li><FooterLink to={"/"}>About Halal Munch</FooterLink></li>
              <li><FooterLink to={"/ideas"}>Ideas</FooterLink></li>
            </FooterUl>
          </Col>
          <Col sm={4}>
            <FooterUl>
              <li><FooterLink to={"/faq"}>FAQ</FooterLink></li>
              <li><FooterLink to={"/contact"}>Contact Us</FooterLink></li>
              <li><FooterLink to={"/privacy-policy"}>Privacy Policy</FooterLink></li>
              <li><FooterLink to={"/terms"}>Terms</FooterLink></li>
            </FooterUl>
          </Col>
        </Row>
        <hr style={{border: "1px solid #757575", margin: "24px 0"}}/>
        <div>
          <small>{new Date().getFullYear()} Halal Munch</small>
        </div>
      </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  background-color: #262626;
  color: #FFFFFF;
  width: 100%;
  padding: 54px 42px 54px 64px;
`;


const FooterUl = styled.ul`
  list-style: none;
  color: #FFFFFF !important;
  text-align: left;
`;

const FooterLink = styled(Link)`
  color: #FFFFFF !important;
`;

const FooterStaticLink = styled.a`
  color: #FFFFFF !important;
`;

/*

#footer > div {
  margin-left: auto;
  margin-right: auto;
}

#footer hr {
  border: 1px solid #757575;
  margin: 24px 0;
}

#footer ul {
  list-style: none;
  color: #FFFFFF;
  text-align: left;
}
 */

export default Footer;