import React, {Fragment} from 'react';
import {HeroSection} from '../components/hero';
import whiteRedCounterImage from '../assets/images/white-red-counter.png';

function Error404View() {
  return (
      <Fragment>
        <div className="container">

          <HeroSection
              title={'404'}
              subtitle={'Not Found'}
              image={whiteRedCounterImage}
              imageAlt={'white-red-counter'}
          />

          <section className="padded">
            <div>
              <h4 className="question">This is awkward</h4>
              <p className="answer">
                So... how did you get yourself here?
              </p>
            </div>
          </section>
        </div>
      </Fragment>
  )
}

export default Error404View;