import React, {Fragment} from 'react';
import {EMAIL} from '../constants/constants';
import whiteRedBackgroundImage from '../assets/images/white-red.png'
import {HeroSection} from '../components/hero';
import whiteRedCounterImage from '../assets/images/white-red-counter.png';

function PrivacyPolicyView() {
  return (
      <Fragment>
        <div className="container">

          <HeroSection
              title={'Privacy Policy'}
              subtitle={'We are committed to keeping your data safe.'}
              image={whiteRedBackgroundImage}
              imageAlt={'white-red-stripe'}
          />

          <section className="padded">
            <h2>Personal Data</h2>
            <p>
              The legal text below applies to information we collect from our
              Website's users (hereinafter, “User” or
              “Users”, as applicable) when you visit <a
                href="https://www.halalmunch.com">halalmunch.com</a>
              (hereinafter, "Website").
            </p>
          </section>

          <section className="padded">
            <h3>What data we collect</h3>
            <p>
              According to the EU General Data Protection Regulation 679/2016/UE
              (hereinafter, GDPR), personal data refers
              to any information relating to an identified or identifiable
              natural person (User), that is, any
              information which can identify a natural person. Therefore, it is
              not applicable to anonymous data.
            </p>
            <p>
              Halal Munch collects various types of personal data, which
              include:
            </p>
            <ol>
              <li>
                <b>Profile Data: </b> username and password, subscriptions,
                comments, likes, uploads or downloads,
                or any optional data such as social media links.
              </li>
              <li>
                <b>Contact Data: </b> email address.
              </li>
              <li>
                <b>Identity Data: </b> first name and last name.
              </li>
              <li>
                <b>Technical Data: </b> language, Internet Protocol (IP)
                address, login information, browser type
                and version, device type, time zone, and other technical data
                used by the User when visiting the
                Website.
              </li>
              <li>
                <b>Usage Data: </b> details on how the User interacts with the
                Website.
              </li>
              <li>
                <b>Communications Data: </b> User communications set by
                preferences to opt-in or opt-out of
                communication
                from Halal Munch and partners.
              </li>
              <li>
                <b>Third Party Data: </b> Other apps such as Google Apps (e.g.
                Google Analytics) collect information on
                Website interaction and activity. Any personal information
                derived from these interactions are not
                stored.
              </li>
            </ol>
            <p>
              Halal Munch does not store or collect any personal data that is
              within the sensitive categories
              (racial or ethnic origin, political opinion, religious or
              philosophic beliefs, membership in a union,
              health information and genetic or biometric data). Halal Munch
              does not store age related information about
              our users, which in turn does not allow us to have specific data
              treatment for minors. Halal Munch is
              exclusively targeted for users of legal age.
            </p>

            <h3>How we collect data</h3>
            <p>
              Halal Munch collects data in various ways, which include:
            </p>
            <ol>
              <li>
                <b>Browsing activity</b>
                <p>
                  General use of the Website is anonymous and does not reflect
                  any personal data.
                </p>
                <p>
                  Automated tools and technologies collect and store data when a
                  User visits our Website. This
                  information is related to device information, browsing
                  activity and usage patterns. Some of this
                  data is collected through cookies and other similar
                  technologies.
                  We also store information about the number of requests made by
                  a User, this is to control the
                  number of requests a single user can make, including checks to
                  ensure suspicious calls are not
                  being made that could breach security.
                </p>
                <p>
                  A User has the choice to allow the Website to use the User's
                  current location to run the Website
                  and its services. This can be enabled/disabled by the
                  browser/device. Location data is explicitly
                  stored, however we log application requests which include
                  related locational data.
                </p>
              </li>
              <li>
                <b>Email, forms and other requests</b>
                <p>
                  Emails sent to <a href={`mailto:${EMAIL}`}>{EMAIL}</a> are
                  recorded
                  and stored for training, regulation and archiving purposes.
                  This can be sent via any contact forms
                  within the Website or explicitly via email. Personal data
                  should not be sent via email.
                </p>
              </li>
            </ol>

            <h3>How we use your data</h3>
            <p>
              When using the Halal Munch, we may collect data through user usage
              to help and improve our services and
              provide you, the user, the best experience.
              <br/>
              The kind of data we collect revolve around analytics and behaviour
              of users interacting with the
              application.
              This allows us to see user behaviour within the application, which
              can help in improving the service to fit
              user needs.
              <br/>
            </p>

            <h3>Rights of the User</h3>
            <p>
              Halal Munch guarantees the exercise of rights established in the
              GDPR.
              The User can exercise the rights described below through the
              support form available on the Website or
              by emailing us at <a href={`mailto:${EMAIL}`}>{EMAIL}</a>.
              Note: Halal Munch may require the User to verify their identity
              before taking action on the
              request for the exercise of rights.
            </p>
            <p>
              The User has the following rights:
            </p>
            <ul>
              <li>
                <b>Right to Access:</b> The User has the right to obtain from
                Halal Munch confirmation about whether or
                not personal data concerning them are being processed and, if
                that is the case, receive information
                about it.
              </li>
              <li>
                <b>Right to Rectification:</b> The User has the right to request
                us to correct personal data where it is
                inaccurate or incorrect and, where necessary, the request must
                be sent together with
                supplementary documentation that accounts for the incomplete or
                inaccurate data.
              </li>
              <li>
                <b>Right to Erasure:</b> The User has the right to request the
                erasure of personal data concerning their
                personal account by emailing us at
                <a href={`mailto:${EMAIL}`}>{EMAIL}</a>.
                When requesting for terminating the account and, therefore,
                erasing all personal information, the User
                must note the following:<br/>
                Halal Munch may keep and use personal information where it is
                necessary in compliance with the legal
                obligations enforced by fiscal obligations and connected to
                judicial information and auditing.
                If the User sends an erasure request, the personal data will be
                erased from back-up systems
                at Halal Munch when the next back-up is done.
              </li>
              <li>
                <b>Right to Restriction of Processing:</b> The User has the
                right to request to restrict processing of
                personal data given the following criteria are met.
                <br/>
                The accuracy of the personal data is contested by the data
                subject, for a period enabling
                Halal Munch to verify the accuracy of the personal data.
                <br/>
                Halal Munch no longer need the personal data for purposes of
                processing, but they are required by the
                data subject for establishment, exercise or defence of legal
                claims.
                <br/>
                The data subject has objected to processing pursuant to Article
                21(1) of the GDPR pending the
                verification whether the legitimate grounds of Halal Munch
                override those of the data subject.
                <br/>
                Given the criteria above are met the User can request to
                restrict processing at any time by emailing
                us at <a href={`mailto:${EMAIL}`}>{EMAIL}</a>.
              </li>
              <li>
                <b>Right to Object:</b> The User has the right to object to the
                processing of their personal data by
                Halal Munch at any time.
              </li>
              <li>
                <b>Right to Data Portability:</b> The User has the right to
                request us a copy of the personal
                information provided to Halal Munch in a structured form and of
                common and legible use.
                The user is also entitled to request that this information be
                sent to another service provider (where
                technically possible).
              </li>
              <li>
                <b>Right to Withdraw Consent:</b> In the cases in which the User
                has authorized their data processing,
                the User has the right to withdraw their consent at any time.
              </li>
            </ul>
            <p>
              The User is responsible for updating their personal information.
            </p>

            <h3>Disclosure of Data</h3>
            <p>
              Halal Munch does not share collected personal data with third
              parties.
              The information provided will only be used for identifying the
              User and managing the account on the
              Website.
            </p>

            <h3>Data Security</h3>
            <p>
              Halal Munch has strong security implementations in place to
              protect User's personal data against misuse,
              unauthorised access, disclosure, loss or alteration.
            </p>

            <h3>Data Usage</h3>
            <p>
              When using the Halal Munch, we may collect data through user usage
              to help and improve our services and
              provide you, the user, the best experience.
              <br/>
              The kind of data we collect revolve around analytics and behaviour
              of users interacting with the
              application.
              This allows us to see user behaviour within the application, which
              can help in improving the service to fit
              user needs.
            </p>
            <ul>
              <li>
                <b>Location:</b> Halal Munch uses geo-location to fetch your
                current location. Users can disable this in
                the browser if concerned, however for the best possible
                experience we suggest to allow location settings
                within the app.
              </li>
              <li>
                <b>Cookies:</b> Halal Munch uses cookies to ensure our Website
                is secure from malicious requests.
                Other third parties and affiliates may store cookies to track
                User behaviour and patterns within the
                Website. You can always opt-out of cookies by clearing the
                cookies in your browser.
              </li>
            </ul>

          </section>

          <br/>
        </div>

      </Fragment>
  )
}

export default PrivacyPolicyView;