import axios from 'axios';
import {API_ENQUIRIES_CONTACT_URL} from '../constants/urls';

class ApiService {

  async postEnquiry(email: string, subject: string, enquiry: string, recaptcha: string): void {
    let response;
    response = await axios.post(API_ENQUIRIES_CONTACT_URL, {
      email: email,
      subject: subject,
      enquiry: enquiry
    }, {
      // params: {
      //   recaptcha: recaptcha
      // }
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Failed to send enquiry');
    }
  }
}

export default new ApiService();