import React, {Fragment} from 'react';
import whiteRedCounterImage from '../assets/images/white-red-counter.png'
import {HeroSection} from '../components/hero';

function IdeasView() {
  return (
      <Fragment>
        <div className="wrapper">

          <HeroSection
              title={'New Features'}
              subtitle={'Let us know, what you want.'}
              image={whiteRedCounterImage}
              imageAlt={'white-red-counter'}
          />

          <section className="padded">
            <form method="post" action="">
              {/*<div className="form-group">*/}
              {/*  <span className="alert alert-success">*/}
              {/*  /!*{{message}}*!/*/}
              {/*  </span>*/}
              {/*</div>*/}
              {/*<div className="form-group">*/}
              {/*  <span className="alert alert-danger">*/}
              {/*  /!*{{error}}*!/*/}
              {/*  </span>*/}
              {/*</div>*/}

              <div className="form-group">
                <label htmlFor="summary">Summary</label>
                <input type="text" id="summary" name="summary"
                       placeholder="Enter a short summary"
                       className="form-control"/>
              </div>

              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea id="description" name="description"
                          className="form-control" rows="5"></textarea>
              </div>

              <hr/>


              <div className="form-group">
                <label htmlFor="social">Social Platform</label>
                <select id="social" name="social" className="form-control">
                  <option value="twitter">Twitter</option>
                  <option value="instagram">Instagram</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input id="username" name="username" type="text"
                       placeholder="@" className="form-control"/>
                <small className="form-text text-muted">
                  Leave your username and we'll credit you for any new
                  features that make it!
                </small>
              </div>


              <div className="form-group">
                <div className="g-recaptcha"
                     data-sitekey="{{ recaptcha_v2_site_key }}"></div>
              </div>

              <div className="form-group">
                <button id="submit-button" type="submit"
                        className="btn btn-lg btn-block btn-primary">Submit
                  Idea
                </button>
              </div>

              <br/>
            </form>
          </section>

          <br/>

        </div>
      </Fragment>
  );
}

export default IdeasView;